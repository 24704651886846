









































import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { ICreateSelfCare, ISelfCare, IUpdateSelfCare } from '@/interfaces/selfCare';
import { dispatchGetSelfCareById, dispatchUpdateSelfCare } from '@/store/crud/actions';

@Component({})
export default class SelfCareForm extends AppComponent {
  @Prop({ type: String, required: false, default: null }) public selfCareId: string;

  public valid = false;
  /*
    SelfCare data
  */
  public name: string = '';
  public description: string = '';

  public selfCare: ISelfCare | null = null;
  public forceCreation: boolean = false;
  public busId: number = 0;

  public async initiView() {
    if (!this.isCreation && this.selfCareId && this.selfCareId.length > 0) {
      this.selfCare = await dispatchGetSelfCareById(this.$store, this.selfCareId);
      if (!this.selfCare || Object.keys(this.selfCare).length === 0) {
        this.forceCreation = true; // force creation if selfCare is not found or error with the API
      }
    }
    this.reset();
  }

  /**
   * Resets the form.
   * if the selfCare id is provided, this will be an edit form so existing data is injected into the form
   * otherwise, this will be a creation form and the data will be empty
   */
  public reset() {
    if (this.isCreation) {
      this.name = '';
      this.description = '';
    } else {
      if (this.selfCare) {
        this.name = this.selfCare.name || '';
        this.description = this.selfCare.description || '';
      } else {
        // weird  case, we just force creation and re-call the function
        this.forceCreation = true;
        this.reset();
        return;
      }
    }
    this.$validator.reset();
  }

  public cancel() {
    this.$emit('selfCare:cancelled');
  }

  public get isCreation() {
    return this.selfCareId === null || this.forceCreation;
  }

  public async mounted() {
    this.initiView();
    this.busId = this.$bus.$on('selfCare:reset', this.initiView);
  }

  public beforeDestroy() {
    this.$bus.$off('selfCare:reset', this.busId);
  }



  /**
   * Updates the selfCare
   * Checks if the form variables are valid / available
   * and only sends the data if it changed from the original data
   * @Argument {boolean} silent - false by default, if true it emits an
   * selfCare:updated event to notify parent components that a new selfCare has been
   * created
   */
  public async updateSelfCare(silent = false) {
    const uploadPayload: IUpdateSelfCare = {};
    if (this.selfCare && this.selfCare.id) {
      this.setFieldIfExistAndValueChanged(uploadPayload, 'name', this.name, this.selfCare.name);
      this.setFieldIfChanges(uploadPayload, 'description', this.description, this.selfCare.description);

      if (Object.keys(uploadPayload).length > 0) {
        const result = await dispatchUpdateSelfCare(
          this.$store, { selfCareId: this.selfCare.id, selfCare: uploadPayload });
        if (result) {
          if (!silent) {
            this.$emit('selfCare:updated', result);
          }
        } else {
          if (!silent) {
            this.toast('Error while updating selfCare', true);
          }
        }
      }
    }
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.isCreation) {
        const protoSelfCare: ISelfCare = {
          name: this.name,
          description: this.description,
        };
        // sanitize creation data
        if (!this.hasValue(protoSelfCare.name)) {
          this.toast('Name is required', true); // name is required
          return;
        }
        if (!this.hasValue(protoSelfCare.description)) { delete protoSelfCare.description; }
        const createSelfCare: ICreateSelfCare = protoSelfCare as ICreateSelfCare;
        this.$emit('selfCare:created', createSelfCare);
      } else {
        this.updateSelfCare();
      }
    }
  }
}
