




import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { dispatchCreateSelfCare } from '@/store/crud/actions';
import SelfCareForm from '@/components/forms/SelfCareForm.vue';
import { ICreateSelfCare } from '@/interfaces/selfCare';


@Component({ components: { SelfCareForm } })
export default class SelfCare extends AppComponent {
  @Prop({ type: String, required: false }) public selfCareId: string;

  public selfCareUpdated() {
    this.$router.push('/main/content/selfcare');
  }

  public async selfCareCreated(selfCarePayload: ICreateSelfCare) {
    if (selfCarePayload && Object.keys(selfCarePayload).length > 0) {
      const result = await dispatchCreateSelfCare(this.$store, selfCarePayload);
      if (result) {
          this.$router.push('/main/content/selfcare');
      }
    }
  }

  public cancel() {
    this.$router.push('/main/content/selfcare');
  }
}
